import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = ({ title = "" }) => {
  return (
    <section className={styles.one}>
      <H1 text={"Weekly Recap"} addTopMargin={true}></H1>

      <div className={styles.grid}>
        <div>
          <span>
            {title ? title : "Please see our weekly market recap posts below."}
          </span>
        </div>
      </div>
    </section>
  )
}

export default One
