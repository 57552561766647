import React, { useState, useEffect, Fragment } from "react"
import { API_URL } from "../utils/helpers"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../recapPage/One"
import Footer from "../components/Footer"

const RecapPage = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const init = async () => {
      const url = new URL(`${API_URL}/recap`)
      const params = {
        init: "1",
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          setData({ ...json })
        }
        setLoading(false)
      } catch (error) {}
    }

    init()
  }, [])

  return (
    <Fragment>
      <Helmet
        title="ETF Stock Report Weekly Recap"
        descr="Read our quick overview of highlights in the investing world for the week that was."
        slug="/recap"
      />
      <Header />
      {!loading && <One dataInit={data} />}
      <Footer />
    </Fragment>
  )
}

export default RecapPage
